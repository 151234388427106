import { appAxios } from "@/utils/appAxios";

const state = {
  profil_posts: [],
  is_new_post_component: false,
  is_post_loading: false,
  is_celebration: false,
};
const getters = {
  // state verisini çağırmak için kullanılır
  get_profil_posts(state) {
    return state.profil_posts;
  },
  get_is_new_post_component(state) {
    return state.is_new_post_component;
  },
  get_is_post_loading(state) {
    return state.is_post_loading;
  },
  get_is_celebration(state) {
    return state.is_celebration;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  set_profile_posts(state, payload) {
    state.profil_posts = payload;
  },
  add_profile_post(state, newPostData) {
    state.profil_posts.unshift(newPostData);
  },
  set_is_new_post_component(state, payload) {
    state.is_new_post_component = payload;
  },
  set_is_post_loading(state, payload) {
    state.is_post_loading = payload;
  },
  set_is_celebration(state, payload) {
    state.is_celebration = payload;
  },
};

const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
  create_new_post: ({ dispatch, commit }, payload) => {
    appAxios
      .post("user/NewPost/", payload)
      .then((response) => {
        const newPostData = response.data;
        commit("add_profile_post", newPostData);
        dispatch("update_user_data");
      })
      .catch((e) => console.log(e));
  },
  download_profile_post: ({ commit, getters }, payload) => {
    commit("set_profile_posts", []);
    commit("set_is_post_loading", true);
    if (getters.get_active_profile.id) {
      appAxios
        .post("user/GetProfilePost/", { userProfileId: payload })
        .then((response) => {
          commit("set_profile_posts", response.data);
          commit("set_is_post_loading", false);
        })
        .catch((e) => console.log(e));
    } else {
      commit("set_profile_posts", []);
      commit("set_is_post_loading", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
